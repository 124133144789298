import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
} from '@angular/router';
import { AuthSettingsService } from '@core/services/auth/auth-settings.service';
import { AuthenticationService } from '@core/services/auth/auth.service';
import { LogoService } from '@core/services/logo/logo.service';
import { FaviconService } from '@core/services/settings/favicon.service';
import { Settings } from '@core/services/settings/settings.interface';
import { SettingsService } from '@core/services/settings/settings.service';
import { environment } from '@environments/environment';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dd-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public loading = true;

    constructor(
        private settingsService: SettingsService,
        private logoService: LogoService,
        private authService: AuthenticationService,
        private authSettings: AuthSettingsService,
        private faviconService: FaviconService,
        private router: Router,
        private translateService: TranslateService,
        private ngSelectConfig: NgSelectConfig,
    ) {
        this.translateService.addLangs(['de', 'en']);
        this.translateService.setDefaultLang('de');
        this.translateService.use('de');
        this.translateService.onLangChange
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.ngSelectConfig.addTagText = this.translateService.instant(
                    'NG_SELECT.ADD_TAG_TEXT',
                );
                this.ngSelectConfig.clearAllText =
                    this.translateService.instant('NG_SELECT.CLEAR_ALL_TEXT');
                this.ngSelectConfig.loadingText = this.translateService.instant(
                    'NG_SELECT.LOADING_TEXT',
                );
                this.ngSelectConfig.notFoundText =
                    this.translateService.instant('NG_SELECT.NOT_FOUND_TEXT');
                this.ngSelectConfig.typeToSearchText =
                    this.translateService.instant(
                        'NG_SELECT.TYPE_TO_SEARCH_TEXT',
                    );
            });
    }

    public ngOnInit(): void {
        this.router.events.subscribe((event) => {
            this.navigationInterceptor(event as RouterEvent);
        });
        this.authSettings.updatePath('');
        this.authSettings.updateToken('access_token');
        this.authSettings.updateCurrentModule('system');
        this.addSystemStyles();
        this.authService.loggedIn.subscribe(() => {
            this.router.navigateByUrl('/admin/dashboard');
        });
    }

    // Shows and hides the loading spinner during RouterEvent changes
    private navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true;
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            this.loading = false;
        }
    }

    private addSystemStyles(): void {
        this.faviconService.setFavIcon('assets/favicons/favicon.so.ico');
        let logo = 'assets/images/logos/systemadministrator_logo.svg';
        this.settingsService.settings$.subscribe((settings: Settings) => {
            if (settings.logo) {
                logo = `${environment.apiUrl}/storage/${settings.logo}`;
            }
            this.logoService.loginLogo = logo;
        });
    }
}
