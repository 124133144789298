import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Directive({
    selector: 'mrc-form-item-field',
})
export class SetFormItemFieldInputAttributesDirective implements AfterViewInit {
    @Input() public id: string;
    @Input() public control: AbstractControl;
    private childNativeFormItemElemRef: ElementRef;
    private validationErrorsElemRef: ElementRef;

    constructor(
        private readonly formItemFieldElemRef: ElementRef,
        private readonly renderer: Renderer2,
    ) {}

    public ngAfterViewInit(): void {
        this.childNativeFormItemElemRef = this.getChildNativeFormItemElemRef();
        this.validationErrorsElemRef = this.getValidationErrorsElemRef();
        this.setDescribeByLinkFromInputToErrorList();
        this.setRequiredHTMLAttributeIfValueIsRequired();
    }

    private setRequiredHTMLAttributeIfValueIsRequired(): void {
        if (this.control?.hasValidator(Validators.required)) {
            this.renderer.setAttribute(
                this.childNativeFormItemElemRef,
                'required',
                'true',
            );
        }
    }

    private setDescribeByLinkFromInputToErrorList(): void {
        if (this.childNativeFormItemElemRef && this.validationErrorsElemRef) {
            const describedbyId = uuidv4();
            this.renderer.setAttribute(
                this.childNativeFormItemElemRef,
                'aria-describedby',
                (
                    describedbyId +
                    ' ' +
                    this.childNativeFormItemElemRef.nativeElement?.getAttribute(
                        'aria-describedby',
                    )
                ).trim(),
            );
            this.renderer.setAttribute(
                this.validationErrorsElemRef,
                'id',
                describedbyId,
            );
        }
    }

    private getValidationErrorsElemRef(): ElementRef {
        return this.formItemFieldElemRef.nativeElement.querySelector(
            'dd-validation-errors',
        );
    }

    private getChildNativeFormItemElemRef(): ElementRef {
        return this.formItemFieldElemRef.nativeElement.querySelector(
            'input,select,textarea',
        );
    }
}
