import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    DestroyRef,
    inject,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdditionalFieldService } from '@app/additional-fields/additional-field.service';
import { FieldType } from '@app/additional-fields/enums/field-type';
import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import { duplicateAttributeValidator } from '@app/additional-fields/validators/duplicate-attribute.validator';
import { CompaniesService } from '@companies/companies.service';
import { COST_CENTER, NUMBER } from '@core/constants/regex';
import { LogoService } from '@core/services/logo/logo.service';
import { TypedFormGroup } from '@core/types/form.type';
import { checkEmailValidator } from '@core/validators/check-email-validator';
import { CustomEmailValidator } from '@core/validators/custom-email.validator';
import { CustomPasswordValidators } from '@core/validators/custom-password.validators';
import {
    PrivacyPolicyComponent,
    PrivacyPolicyData,
    PrivacyPolicyType,
} from '@layout/privacy-policy/privacy-policy.component';
import { PortalService } from '@portals/portal.service';
import { Company } from '@shared/models/company';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, pairwise, startWith, throwError } from 'rxjs';
import { checkNameInPasswordValidator } from '@core/validators/check-name-in-password.validator';

interface RegistrationInfo {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
    password_confirmation: string;
    employee_number?: string;
    cost_centre?: string;
    company_id?: number;
    calculator: number;
    attributes?: FieldAttribute[];
}

@Component({
    selector: 'mrc-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    @ViewChild('policyModal') private policyModal: TemplateRef<any>;
    public submitProcess = false;
    public company: Company;
    public childCompanies: Company[] = [];
    public modalRef: BsModalRef;
    public policy: string;
    public allow_public_registration: boolean;
    public registrationForm: FormGroup<
        TypedFormGroup<{
            selectedCompany: Company;
            calculator: boolean;
            email: string;
            firstName: string;
            lastName: string;
            password: string;
            passwordConfirmation: string;
            employeeNumber?: string;
            costCenter?: string;
            [key: string]: any;
        }>
    >;
    public slug = this.activatedRoute.snapshot.params.companySlug;

    constructor(
        public logoService: LogoService,
        private portalService: PortalService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private toaster: ToastrService,
        private companiesService: CompaniesService,
        private modalService: BsModalService,
        private additionalFieldService: AdditionalFieldService,
        private dialog: MatDialog,
    ) {}

    public ngOnInit(): void {
        this.initForm();
        this.companiesService.loadCompanyDataBySlug(this.slug);

        this.companiesService._companyData$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (company: Company) => {
                    if (company) {
                        this.registrationForm.controls.selectedCompany.setValue(
                            company,
                        );
                    }
                },
            });

        this.companiesService._childCompanies$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (childCompanies: Company[]) => {
                    if (childCompanies) {
                        this.childCompanies = childCompanies.filter(
                            (company) => !!company.allow_public_registration,
                        );
                        if (this.childCompanies.length > 1) {
                            this.registrationForm.controls.selectedCompany.reset();
                        }
                    }
                },
            });
    }

    private initForm(): void {
        this.registrationForm = new FormGroup({
            selectedCompany: new FormControl(null, [Validators.required]),
            calculator: new FormControl(false, [Validators.required]),
            email: new FormControl(
                '',
                [Validators.required, CustomEmailValidator],
                [checkEmailValidator(this.portalService, false, this.slug)],
            ),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            password: new FormControl('', CustomPasswordValidators),
            passwordConfirmation: new FormControl('', Validators.required),
        });

        // Check if pw contains names
        this.registrationForm.controls.password.addValidators([
            checkNameInPasswordValidator(
                this.registrationForm.controls.firstName,
                this.registrationForm.controls.lastName,
            ),
        ]);
        this.registrationForm.controls.firstName.valueChanges.subscribe(
            (): void => {
                this.registrationForm.controls.password.updateValueAndValidity();
            },
        );
        this.registrationForm.controls.lastName.valueChanges.subscribe(
            (): void => {
                this.registrationForm.controls.password.updateValueAndValidity();
            },
        );

        this.registrationForm.controls.selectedCompany.valueChanges
            .pipe(startWith(0), pairwise(), takeUntilDestroyed(this.destroyRef))
            .subscribe(([prevCompany, nextCompany]: [Company, Company]) => {
                // remove conditional controls first
                if (prevCompany) {
                    this.registrationForm.removeControl('employeeNumber');
                    this.registrationForm.removeControl('costCenter');

                    prevCompany.additional_fields.forEach((field) => {
                        this.registrationForm.removeControl(field.key);
                    });
                }

                if (nextCompany) {
                    // add conditional controls
                    if (nextCompany.required_personal_number) {
                        this.registrationForm.addControl(
                            'employeeNumber',
                            new FormControl('', [Validators.required]),
                        );
                    }
                    if (nextCompany.required_cost_centre) {
                        this.registrationForm.addControl(
                            'costCenter',
                            new FormControl('', [
                                Validators.required,
                                Validators.pattern(COST_CENTER),
                            ]),
                        );
                    }
                    this.allow_public_registration =
                        nextCompany.allow_public_registration;

                    nextCompany.additional_fields.forEach((field) => {
                        const control = new FormControl<string>(null);

                        if (field.type === FieldType.NUMBER) {
                            control.addValidators(Validators.pattern(NUMBER));
                        }

                        if (field.minLength) {
                            control.addValidators(
                                Validators.minLength(field.minLength),
                            );
                        }

                        if (field.maxLength) {
                            control.addValidators(
                                Validators.maxLength(field.maxLength),
                            );
                        }

                        if (field.isUnique) {
                            control.addAsyncValidators(
                                duplicateAttributeValidator(
                                    this.additionalFieldService,
                                    field.key,
                                    nextCompany.slug,
                                ),
                            );
                        }

                        if (field.required) {
                            control.addValidators(Validators.required);
                        }

                        this.registrationForm.addControl(field.key, control);
                    });
                }

                this.registrationForm.updateValueAndValidity();
                this.company = nextCompany;
            });
    }

    public register(): void {
        if (this.registrationForm.invalid || this.submitProcess) {
            return;
        }

        const data: RegistrationInfo = {
            calculator: this.registrationForm.controls.calculator.value ? 1 : 0,
            email: this.registrationForm.controls.email.value,
            first_name: this.registrationForm.controls.firstName.value,
            last_name: this.registrationForm.controls.lastName.value,
            password: this.registrationForm.controls.password.value,
            password_confirmation:
                this.registrationForm.controls.passwordConfirmation.value,
        };

        data.company_id =
            this.registrationForm.controls.selectedCompany.value.id;

        if (
            this.registrationForm.controls.selectedCompany.value
                .additional_fields.length > 0
        ) {
            data.attributes = [];
            this.registrationForm.controls.selectedCompany.value.additional_fields.forEach(
                (field) => {
                    data.attributes.push({
                        key: field.key,
                        value: this.registrationForm.get(field.key).value,
                    });
                },
            );
        }

        if (this.registrationForm.contains('employeeNumber')) {
            data.employee_number =
                this.registrationForm.get('employeeNumber').value;
        }

        if (this.registrationForm.contains('costCenter')) {
            data.cost_centre = this.registrationForm.get('costCenter').value;
        }

        this.submitProcess = true;

        this.http
            .post('registration/register', data)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                catchError((error: HttpErrorResponse) => {
                    // Darius Enders:
                    // this error actually not handled by the error handling below
                    // TODO(86by027xx): implement better exception handling or resolve this maybe in duplicate email validator
                    if (
                        error.error.exceptionCode &&
                        (error.error.exceptionCode ===
                            'invalidRegisterEmailDomain' ||
                            error.error.exceptionCode ===
                                'blacklistedEmailDomain')
                    ) {
                        this.toaster.error(error.error.message);
                        return throwError(() => error);
                    }
                    // Patrick Cöper:
                    // this is needed in case the backend throws an error
                    // because the passed email is alreay in use. In the
                    // future we will handle that case with an async validator...
                    const errorReason = Object.values(
                        error.error.payload,
                    )[0] as string;

                    this.toaster.error(errorReason, error.error.message);
                    return throwError(() => error);
                }),
                finalize(() => (this.submitProcess = false)),
            )
            .subscribe(() => {
                if (this.company.enable_employee_registration) {
                    this.toaster.info(
                        'Ihre Registrierung wird von Ihrem Arbeitgeber überprüft. Sie werden per Email benachrichtigt sobald die Freischaltung erfolgt ist.',
                        'Genehmigung ausstehend',
                    );
                } else {
                    this.toaster.success('Sie wurden erfolgreich registriert');
                }

                this.router.navigate(['../login'], {
                    relativeTo: this.activatedRoute.parent,
                });
            });
    }

    public openModal(): void {
        this.modalRef = this.modalService.show(this.policyModal);
    }

    public openPrivacyPolicyDialog(): void {
        this.dialog.open<PrivacyPolicyComponent, PrivacyPolicyData, void>(
            PrivacyPolicyComponent,
            {
                panelClass: 'mat-mdc-dialog-surface--no-padding',
                data: {
                    type: PrivacyPolicyType.PRIVACY_POLICY,
                    slug: this.slug,
                },
            },
        );
    }
}
