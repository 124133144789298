import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import {
    FinancialData,
    RealtimeFinancialData,
} from '@core/interfaces/financial-data.interface';
import { HasMany } from '@core/relations/has-many';
import { HasOne } from '@core/relations/has-one';
import { Audit } from './audit';
import { Company } from './company';
import { InsuranceRate } from './insurance-rate';
import { Leasing } from './leasing';
import { LeasingCondition } from './leasing-condition';
import { Model } from './model';
import { Offer } from './offer';
import { OfferAccessory } from './offer-accessory';
import { Order } from './order';
import { ProductCategory } from './product-category';
import { ServiceRate } from './service-rate';
import { Supplier } from './supplier';
import {
    TechnicalService,
    TechnicalServiceModality,
} from './technical-service';
import { User } from './user';
import { Vat } from './vat';

export enum ContractStatus {
    Active = 15,
    Inactive = 16,
    Cancelled = 21,
    Return = 40,
    Buyout = 41,
    CompanyBuyout = 46,
    Rescinded = 52,
}

export enum EndOfContractStatus {
    Return = 'Return',
    Buyout = 'Buyout',
}

@HasOne(LeasingCondition, 'leasingCondition')
@HasOne(Company, 'company')
@HasOne(User, 'user')
@HasOne(Offer, 'offer')
@HasOne(Order, 'order')
@HasMany(InsuranceRate, 'insuranceRates')
@HasMany(ServiceRate, 'serviceRates')
export class Contract extends Model implements Leasing {
    public technicalServicesSummary: any;
    public technicalServiceId: any;
    public insuranceRate?: InsuranceRate;
    public serviceRate?: ServiceRate;
    public grossAmount: number;
    public inspectionCode: string;
    public companyUserID: number;
    public supplierUserID: number;
    public supplierCode: string;
    public supplierName: string;
    public supplierStreet: string;
    public supplierPostalCode: string;
    public supplierCity: string;
    public senderName: string;
    public employeeID: number;
    public costCentre: string;
    public employeeNumber: string;
    public employeeCostCenter: string;
    public employeeFrontName: string;
    public employeeName: string;
    public employeeCode: string;
    public employeeSalutation: string;
    public employeeStreet: string;
    public employeePostalCode: string;
    public employeeCity: string;
    public employeeEmail: string;
    public employeePhone: string;
    public productCategory: ProductCategory;
    public productBrand: string;
    public productModel: string;
    public productColor: string;
    public productSize: string;
    public taxRate: number;
    public vat: Vat;
    public invoiceType: string;
    public offerNumber: string;
    public frameNumber: string;
    public contract_number: string;
    public accessoriesPrice: number;
    public accessoriesDiscountedPrice: number;
    public accessoriesResidualValueCustomer: number;
    public residual_value_customer: number;
    public customerResidualValue: number;
    public productResidualValueCustomer: number;
    public productListPrice: number;
    public productDiscountedPrice: number;
    public productDiscount: number;
    public service_rate_name: string;
    public service_rate_amount: string;
    public insurance_rate_name: string;
    public insurance_rate_amount: string;
    public company: Company;
    public user: User;
    public offer: Offer;
    public order: Order;
    public date: Date;
    public status: any;
    public cancellationReason: string;
    public number: string;
    public start_date: string;
    public end_date: string;
    public serialNumber: string;
    public notes: string;
    public deliveryDate: string;
    public serviceBudget: number;
    public remainingServiceBudget: number;
    public agreedPurchasePrice: number;
    public apartmentWorkplaceDistance: number;
    public audits: Audit[];
    public lastTechnicalService: TechnicalService;
    public pickupDate: string;
    public creationDate: string;
    public parentSupplier: Supplier;
    public homeOfficeTravelTax: number;
    public realtime_financial_data: RealtimeFinancialData;
    public financial_data: FinancialData;
    public display_financial_data: RealtimeFinancialData;
    public no_cancel_credit_financial_data: RealtimeFinancialData;
    public bank: any;
    public companyName: string;
    public serviceRateModality: TechnicalServiceModality;
    public companyBankId: number;
    public latestActiveInsuranceId: number;
    public cancelDate: string;

    public employeeAttributes: FieldAttribute[] = [];

    public getAccessories(): OfferAccessory[] {
        if (this.offer) {
            return this.offer.accessories;
        }
        return null;
    }
}
