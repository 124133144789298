import { AfterViewInit, Directive, Host } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: 'ng-select',
    standalone: true,
})
export class NgSelectDirective implements AfterViewInit {
    constructor(
        @Host() private hostComponent: NgSelectComponent,
        private translateService: TranslateService,
    ) {
        hostComponent.openEvent
            .asObservable()
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                setTimeout(() => {
                    this.a11yDropdownPanel();
                });
            });
        hostComponent.changeEvent
            .asObservable()
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                if (this.hostComponent.showClear()) {
                    setTimeout(() => {
                        this.a11yClearButton();
                    });
                }
            });
    }

    public ngAfterViewInit(): void {
        if (this.hostComponent.showClear()) {
            this.a11yClearButton();
        }
    }

    private a11yDropdownPanel(): void {
        // @ts-expect-error private elementRef
        const dropdownPanel = this.hostComponent.dropdownPanel._dropdown;
        if (!dropdownPanel) {
            return;
        }

        dropdownPanel.removeAttribute('role');
        dropdownPanel.setAttribute(
            'aria-label',
            this.translateService.instant('NG_SELECT.ARIA_LABEL_DROPDOWN'),
        );
    }

    private a11yClearButton(): void {
        const clearButton = this.hostComponent.clearButton?.nativeElement;
        if (!clearButton) {
            return;
        }

        clearButton.setAttribute('role', 'button');
        clearButton.style.minWidth = '24px';
        clearButton.style.minHeight = '24px';
        clearButton.style.position = 'relative';
        clearButton.setAttribute('aria-label', clearButton.title);
        clearButton.removeAttribute('title');

        const icon = <HTMLSpanElement>clearButton.firstElementChild;
        icon.style.position = 'absolute';
        icon.style.top = '50%';
        icon.style.left = '50%';
        icon.style.transform = 'translate(-50%, -50%)';
    }
}
