import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class MatPaginatorIntlDe extends MatPaginatorIntl {
    public override itemsPerPageLabel: string;
    public override nextPageLabel: string;
    public override previousPageLabel: string;
    public override firstPageLabel: string;

    public override lastPageLabel: string;

    public override getRangeLabel = function (
        page: number,
        pageSize: number,
        length: number,
    ): string {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('PAGINATOR.X_OF_X', {
                startIndex: 0,
                length,
            });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return this.translate.instant('PAGINATOR.X_TO_X_OF_X', {
            startIndex: startIndex + 1,
            endIndex,
            length,
        });
    };

    constructor(private translate: TranslateService) {
        super();
        this.getTranslations();
        this.translate.onLangChange.subscribe(() => {
            this.getTranslations();
        });
    }

    public getTranslations(): void {
        this.itemsPerPageLabel = this.translate.instant(
            'PAGINATOR.ITEMS_PER_PAGE_LABEL',
        );
        this.nextPageLabel = this.translate.instant(
            'PAGINATOR.NEXT_PAGE_LABEL',
        );
        this.previousPageLabel = this.translate.instant(
            'PAGINATOR.PREVIOUS_PAGE_LABEL',
        );
        this.firstPageLabel = this.translate.instant(
            'PAGINATOR.FIRST_PAGE_LABEL',
        );
        this.lastPageLabel = this.translate.instant(
            'PAGINATOR.LAST_PAGE_LABEL',
        );
        this.changes.next();
    }
}
